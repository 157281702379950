<template>
  <div>
    <b-form ref="formCreateClass" @submit.prevent="submitCreate">
      <div class="form-row">
        <div class="col-md-8">
          <b-row>
            <b-col md="12">
              <b-card header="Informações básicas" class="mb-1">
                <b-card-text>
                  <b-row class="form-row">
                    <b-col md="12">
                      <b-form-group>
                        <label for="">
                          <i class="text-danger bi bi-record-circle"></i>
                          Título
                        </label>
                        <b-form-input
                          required
                          v-model="item.title"
                          placeholder="Título"
                          :class="{ 'is-invalid': $v.item.title.$error }"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col md="8">
                      <label for="" style="display: flow-root">
                        <span class="float-left">Subtítulo</span>
                      </label>
                      <b-form-group>
                        <b-form-input
                          v-model="item.subtitle"
                          placeholder="Subtítulo"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col md="4">
                      <label for="" style="display: flow-root">
                        <span class="float-left">
                          <i class="text-danger bi bi-record-circle"></i>
                          Horas
                        </span>
                      </label>
                      <b-form-group>
                        <b-form-input
                          v-mask="['#######']"
                          v-model="item.time"
                          placeholder="Horas"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-card>
            </b-col>

            <b-col md="12">
              <div
                class="card shadow-none border-0 mb-0 rounded-0 card-header-custom-actions"
              >
                <div class="card-header">
                  <div class="form-row">
                    <div class="col-md-10 col-8">
                      <h4 class="mb-0">
                        <i class="text-danger bi bi-record-circle"></i>
                        Aulas
                        <small class="d-block"
                          >Vincule as aulas relacionadas a esta
                          disciplina</small
                        >
                      </h4>
                    </div>
                    <div class="col-md-2 col-4">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        @click="$bvModal.show('createLesson')"
                        class="btn-block"
                      >
                        <i class="bi bi-plus mr-25"></i>
                        <span>Novo</span>
                      </b-button>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <draggable
                    v-if="item.lessons.length"
                    tag="ul"
                    :list="item.lessons"
                    class="list-group"
                    handle=".handle"
                  >
                    <li
                      class="list-group-item"
                      v-for="(element, idx) in item.lessons"
                      :key="element.title"
                    >
                      <div class="form-row">
                        <div class="col-md-11">
                          <feather-icon
                            icon="MoveIcon"
                            class="handle"
                            size="15"
                          />
                          <span class="pl-1 text-not-selected"
                            >{{ element.title }}
                          </span>
                        </div>
                        <div class="col-md-1">
                          <button
                            type="button"
                            style="padding: 0px !important"
                            class="btn btn-block btn-sm btn-default text-danger"
                            @click="removeAt(idx)"
                          >
                            <feather-icon icon="XCircleIcon" size="20" />
                          </button>
                        </div>
                      </div>
                    </li>
                  </draggable>
                  <p v-else>Nemnhum aula vinculada até o momento.</p>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="col-md-4">
          <b-card header="Tags">
            <b-card-text>
              <b-form-group>
                <label for="">
                  <i class="text-danger bi bi-record-circle"></i>
                  Tag Tema
                </label>
                <v-select
                  ref="selectTema"
                  multiple
                  label="title"
                  item-text="title"
                  item-value="code"
                  v-model="item.tags.subject"
                  placeholder="Digite o título da tag"
                  :options="optionsTagTema"
                  @search="fetchTagsCategoryTema"
                  :class="{ 'is-invalid': $v.item.tags.subject.$error }"
                >
                  <span
                    slot="no-options"
                    @click="$refs.selectTema.open = false"
                  >
                    Nenhum registro encontrado
                  </span>
                </v-select>
              </b-form-group>

              <b-form-group>
                <label for="">
                  <i class="text-danger bi bi-record-circle"></i>
                  Tag Concurso
                </label>
                <v-select
                  ref="selectContest"
                  multiple
                  label="title"
                  item-text="title"
                  item-value="code"
                  v-model="item.tags.contest"
                  placeholder="Digite o título da tag"
                  :options="optionsTagConcurso"
                  @search="fetchTagsCategoryConcurso"
                  :class="{ 'is-invalid': $v.item.tags.contest.$error }"
                >
                  <span
                    slot="no-options"
                    @click="$refs.selectContest.open = false"
                  >
                    Nenhum registro encontrado
                  </span>
                </v-select>
              </b-form-group>

              <b-form-group class="mb-0">
                <label for="">
                  <i class="text-danger bi bi-record-circle"></i>
                  Tag Linha do tempo
                </label>
                <v-select
                  ref="selectTimeline"
                  multiple
                  label="title"
                  item-text="title"
                  item-value="code"
                  v-model="item.tags.timeline"
                  placeholder="Digite o título da tag"
                  :options="optionsTagLinhaDoTempo"
                  @search="fetchTagsCategoryLinhaDoTempo"
                  :class="{ 'is-invalid': $v.item.tags.timeline.$error }"
                >
                  <span
                    slot="no-options"
                    @click="$refs.selectTimeline.open = false"
                  >
                    Nenhum registro encontrado
                  </span>
                </v-select>
              </b-form-group>
            </b-card-text>
          </b-card>
          <div class="form-row">
            <div class="col-md-5">
              <b-button
                variant="light"
                @click="$router.push({ name: 'disciplines-list' })"
                class="btn btn-light mr-1 btn-block"
              >
                Voltar
              </b-button>
            </div>
            <div class="col-md-7">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                type="submit"
                :disabled="submited"
                class="btn-block"
              >
                <div v-if="submited">
                  <b-spinner small variant="light" class="left" /> aguarde...
                </div>
                <div v-else>Cadastrar</div>
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </b-form>

    <b-modal
      v-model="modalShow"
      id="createLesson"
      centered
      title="Selecionar aula"
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      size="lg"
    >
      <b-form @submit.prevent="submitFindLessons">
        <b-card-text>
          <div class="form-row justify-content-between">
            <b-col
              cols="12"
              md="4"
              class="align-items-center justify-content-left"
            >
              <div class="form-group">
                <label for="">Tag Tema</label>
                <v-select
                  ref="selectTema"
                  label="title"
                  item-text="title"
                  item-value="code"
                  v-model="filters.tag_subject"
                  placeholder="Digite o título"
                  :options="optionsTagTema"
                  @search="fetchTagsCategoryTema"
                >
                  <span
                    slot="no-options"
                    @click="$refs.selectTema.open = false"
                  >
                    Nenhum registro encontrado
                  </span>
                </v-select>
              </div>
            </b-col>
            <b-col
              cols="6"
              md="4"
              class="d-none d-sm-block align-items-center justify-content-left"
            >
              <div class="form-group">
                <label for="">Tag Concurso</label>
                <v-select
                  ref="selectConcurso"
                  label="title"
                  item-text="title"
                  item-value="code"
                  v-model="filters.tag_contest"
                  placeholder="Digite o título"
                  :options="optionsTagConcurso"
                  @search="fetchTagsCategoryConcurso"
                >
                  <span
                    slot="no-options"
                    @click="$refs.selectConcurso.open = false"
                  >
                    Nenhum registro encontrado
                  </span>
                </v-select>
              </div>
            </b-col>
            <b-col cols="6" md="4">
              <div class="form-group">
                <label for="">Tag Linha do tempo</label>
                <v-select
                  ref="selectLinhadoTempo"
                  label="title"
                  item-text="title"
                  item-value="code"
                  v-model="filters.tag_timeline"
                  placeholder="Digite o título"
                  :options="optionsTagLinhaDoTempo"
                  @search="fetchTagsCategoryLinhaDoTempo"
                >
                  <span
                    slot="no-options"
                    @click="$refs.selectLinhadoTempo.open = false"
                  >
                    Nenhum registro encontrado
                  </span>
                </v-select>
              </div>
            </b-col>
            <b-col cols="6" md="4">
              <div class="form-group">
                <label for="">Professor</label>
                <v-select
                  label="title"
                  item-text="title"
                  item-value="code"
                  v-model="filters.teacher"
                  placeholder="Digite o nome do professor"
                  :options="optionsTeachers"
                  @search="fetchTeachers"
                >
                  <span slot="no-options"> Nenhum registro encontrado </span>
                </v-select>
              </div>
            </b-col>
            <b-col cols="6" md="4">
              <b-form-group>
                <label for="">Título da aula</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Digite o título da aula"
                  v-model="filters.title"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6" md="4">
              <div class="form-group">
                <label for="" style="visibility: hidden">Buscar</label>
                <button
                  class="btn btn-primary btn-block"
                  type="submit"
                  :disabled="submitedFind"
                >
                  <div v-if="submitedFind">
                    <b-spinner small variant="light" class="left" /> buscando...
                  </div>
                  <div v-else>
                    <feather-icon icon="SearchIcon" />
                    BUSCAR
                  </div>
                </button>
              </div>
            </b-col>
          </div>
          <div class="form-row">
            <div class="col-12">
              <div class="area-lessons d-block mt-2" v-if="!dataItems">
                Nenhum registro encontrato até o momento.
              </div>
              <div v-else class="mt-2">
                <b-list-group class="area-lessons mb-1">
                  <b-list-group-item
                    v-for="(row, index) in dataItems"
                    :key="index"
                  >
                    <div class="form-row">
                      <div class="col-md-11" style="line-height: 2">
                        <span class="mr-1">
                          <feather-icon icon="VideoIcon" size="16" />
                        </span>
                        <span>
                          {{ row.title }}
                        </span>
                      </div>
                      <div class="col-md-1">
                        <button
                          type="button"
                          class="btn btn-block btn-sm btn-primary"
                          @click="AddItemToArray(row)"
                        >
                          <feather-icon icon="PlusCircleIcon" size="16" />
                        </button>
                      </div>
                    </div>
                  </b-list-group-item>
                </b-list-group>
                <CustomPaginateTable
                  :rows-table="rowsTable"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :total-rows="totalRows"
                />
              </div>
            </div>
          </div>
        </b-card-text>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BCardText,
  BCard,
  VBTooltip,
  VBToggle,
  BAvatar,
  BEmbed,
  BButton,
  BModal,
  BCardFooter,
  BSpinner,
  BListGroup,
  BBadge,
  BListGroupItem,
} from "bootstrap-vue";
import { mapState } from "vuex";
import vSelect from "vue-select";
import { required, minLength } from "vuelidate/lib/validators";
import draggable from "vuedraggable";
import Ripple from "vue-ripple-directive";
import CustomPaginateTable from "@/views/components/custom/PaginationTable";

export default {
  components: {
    BCard,
    CustomPaginateTable,
    BSpinner,
    BBadge,
    BModal,
    BEmbed,
    BCardText,
    draggable,
    BFormInput,
    BFormGroup,
    vSelect,
    BForm,
    BRow,
    BCol,
    BAvatar,
    BButton,
    BCardFooter,
    BListGroup,
    BListGroupItem,
  },
  data() {
    return {
      dataItems: [],
      currentPage: 1,
      perPage: 20,
      totalRows: 0,
      rowsTable: 0,
      filters: {
        tag_subject: [],
        tag_contest: [],
        tag_timeline: [],
        rangeDate: null,
        teacher: [],
      },
      modalShow: false,
      submited: false,
      submitedFind: false,
      filteredOptions: [],
      wasUploads: 0,
      lessonsOptions: [],
      lesson: null,
      item: {
        title: "",
        subtitle: "",
        lessons: [],
        ead: 1,
        tags: {
          subject: [],
          contest: [],
          timeline: [],
        },
      },
      optionsTagTema: [],
      optionsTagConcurso: [],
      optionsTagLinhaDoTempo: [],
      optionsTeachers: [],
    };
  },
  validations: {
    item: {
      title: {
        required,
        minLength: minLength(5),
      },
      lessons: {
        required,
      },
      tags: {
        subject: {
          required,
        },
        contest: {
          required,
        },
        timeline: {
          required,
        },
      },
    },
  },
  created() {},
  computed: {
    ...mapState("Lesson", ["searchLessons", "lessons"]),
  },
  methods: {
    AddItemToArray(event) {
      let checkUniqDuplicate = false;
      this.lesson = null;
      if (!event) return;

      this.item.lessons.filter((item) => {
        if (item.code === event.uuid) {
          checkUniqDuplicate = true;
        }
      });

      if (!checkUniqDuplicate) {
        this.item.lessons.push({
          code: event.uuid,
          title: event.title,
        });

        this.notify(
          "Aula vinculada com sucesso!",
          "CheckIcon",
          "success",
          null,
          5000
        );
      } else {
        this.notify(
          "Esta aula já está vinculada",
          "BellIcon",
          "warning",
          null,
          5000
        );
      }
    },
    fetchLessonsOptions(term) {
      if (term.length > 2) {
        this.$store.dispatch("Lesson/search", term).then(() => {
          this.lessonsOptions = this.searchLessons;
        });
      }
    },
    removeAt(idx) {
      this.item.lessons.splice(idx, 1);
    },
    submitFindLessons() {
      this.submitedFind = true;
      let filtersSearch = {
        tag_subject: this.filters.tag_subject
          ? this.filters.tag_subject.code
          : "",
        tag_contest: this.filters.tag_contest
          ? this.filters.tag_contest.code
          : "",
        tag_timeline: this.filters.tag_timeline
          ? this.filters.tag_timeline.code
          : "",
        rangeDate: this.filters.rangeDate ?? "",
        teacher: this.filters.teacher ? this.filters.teacher.code : "",
        title: this.filters.title ?? "",
      };

      this.$store
        .dispatch("Lesson/all", {
          currentPage: this.currentPage,
          perPage: this.perPage,
          filters: filtersSearch,
        })
        .then((data) => {
          if (data) {
            this.dataItems = data.data;
            this.rowsTable = data.data.length;
            this.totalRows = data.meta.total;
            this.currentPage = data.meta.current_page;
          }
        })
        .catch((err) => {
          this.submitedFind = false;
          this.notifyDefault("error");
        })
        .finally(() => {
          this.submitedFind = false;
        });
    },
    submitCreate() {
      this.$v.$touch();
      this.submited = true;

      if (!this.$v.$error) {
        this.$store
          .dispatch("Discipline/create", this.item)
          .then(() => {
            this.notifyDefault("success");
            this.$router.push({ name: "disciplines-list" });
          })
          .catch(() => {
            this.notifyDefault("error");
          })
          .finally(() => {
            this.submited = false;
          });
      } else {
        this.submited = false;
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
    async fetchTagsCategoryTema(term) {
      if (term.length > 2) {
        this.optionsTagTema = await this.$store.dispatch("Tag/searchTags", {
          category_id: 1,
          term: term,
        });
      }
    },
    async fetchTagsCategoryConcurso(term) {
      if (term.length > 2) {
        this.optionsTagConcurso = await this.$store.dispatch("Tag/searchTags", {
          category_id: 2,
          term: term,
        });
      }
    },
    async fetchTagsCategoryLinhaDoTempo(term) {
      if (term.length > 2) {
        this.optionsTagLinhaDoTempo = await this.$store.dispatch(
          "Tag/searchTags",
          { category_id: 3, term: term }
        );
      }
    },
    async fetchTeachers(term) {
      if (term.length > 2) {
        this.optionsTeachers = await this.$store.dispatch('Teacher/search', term)
      }
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  mounted() {
    this.$root.$on("paginate:update", (res) => {
      this.currentPage = res._currentPage;
      this.totalRows = res._totalRows;
      this.rowsTable = res._rowsTable;
      this.perPage = res._perPage;
      this.submitFindLessons();
    });
  },
  beforeDestroy() {
    this.$root.$off("paginate:update");
  },
};
</script>

<style>
.beforeUpload .icon {
  width: 50px !important;
}
.area-lessons {
  min-height: 400px;
}
</style>
